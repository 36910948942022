import { fmTime } from '@/utils/tools';
import CompSupportUser from './popup/supportUser.vue';
import CompGoals from './popup/goals.vue';
import { fmoney } from '@/utils/tools.js';
export default {
  props: {
    product_info: {
      // 项目详情
      type: Object
    }
  },
  computed: {
    remaining() {
      return fmTime(this.time_remaining);
    },
    time_count_down() {
      return fmTime(this.count_down);
    },
    process_color() {
      // 众筹中 || 众筹成功
      if (this.product_info.status_code == 0 || this.product_info.status_code == 3) {
        return '#00C4A1';
      } else {
        return '#1A1A1A';
      }
    }
  },
  components: {
    CompSupportUser,
    CompGoals
  },
  data() {
    return {
      remaining_timer: null,
      time_remaining: '',
      count_down: '',
      count_down_timer: null,
      showSupportUser: false,
      showTarget: false,
      status_code_arr: [2, 6, 7, 8, 9]
    };
  },
  async mounted() {
    await this.$nextTick();
    if (this.product_info.status_code == 1 && this.product_info.time_remaining) {
      this.time_remaining = this.product_info.time_remaining;
      this.remaining_timer = setInterval(() => {
        if (this.time_remaining > 0) {
          this.time_remaining -= 1;
        } else {
          clearInterval(this.remaining_timer);
          this.$emit('getInfoRt');
        }
      }, 1000);
    }
    if (this.product_info.status_code == 0 && this.product_info.count_down_time) {
      this.count_down = this.product_info.count_down_time;
      this.count_down_timer = setInterval(() => {
        if (this.count_down > 0) {
          this.count_down -= 1;
        } else {
          clearInterval(this.count_down_timer);
          this.$emit('getInfoRt');
        }
      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.remaining_timer);
    clearInterval(this.count_down_timer);
  },
  methods: {
    fmTime(time) {
      return fmTime(time);
    },
    // 查看支持的人
    onSupportUser() {
      if (this.product_info.is_preview) {
        return false;
      }
      this.showSupportUser = true;
    },
    // 查看众筹目标
    onTarget() {
      if (this.product_info.is_preview) {
        return false;
      }
      this.showTarget = true;
    },
    fNum(num, len = 2) {
      return fmoney(num, len);
    }
  }
};