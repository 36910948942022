import MDRouter from "@/service/router";
import CompPopupBottom from '@@/compose/popupBottom.vue';
import { getRewardList, refreshRewards, checkReward } from '@/api/index';
import { fmTime, computeTextLength } from '@/utils/tools';
import { mdToast } from "../../../utils/tools";
export default {
  props: ['show', "pro_id", "preview_list", "status_code"],
  components: {
    CompPopupBottom
  },
  computed: {
    projectState() {
      // 按钮状态 11 点击刷新  12 众筹还未开始 21 已抢光 22 立即购买支持 3 已截止 4 众筹已结束 
      let state = 4;
      let item = this.reword_item;
      if (item.draw_card_id) {
        return 'luckycards';
      }
      if (item.status) {
        switch (item.status) {
          case 100:
          case 101:
            if (item.online_remaining_time > 0) {
              state = 11; // 点击刷新
            } else if (item.online_time && item.online_time.length > 1) {
              state = 12; // 众筹还未开始
            }
            break;
          case 200:
            if (item.num > 0 && item.back_count >= item.num) {
              state = 21; // 已抢光
            } else {
              if (item.has_chance == 1) {
                state = 11; // 点击刷新
              } else {
                state = 22; // 立即购买支持
              }
            }
            break;
          case 300:
            state = 3; // 已截止
            break;
          default:
            if (this.status_code == 13) {
              state = 13; // 众筹还未开始 -- 项目公开展示-但未设置开售时间
            } else {
              state = 4; // 众筹已结束
            }
            break;
        }
      }
      return state;
    },
    is_preview() {
      return this.preview_list && this.preview_list.length > 0;
    }
  },
  data() {
    return {
      reword_item: {},
      //选中的回报
      chooseIndex: -1,
      //选中的回报档
      reward_list: [],
      show_info_top_bg: true,
      show_reword_list_bg: true,
      remainingTime: '00:00:00',
      // 距离结束倒计时
      remainingTimer: null,
      onlineRemainingTime: '00:00:00',
      // 距离开始倒计时
      onlineRemainingTimer: null,
      showIcon: true,
      shwoIcondown: true,
      showRelatedReward: false
    };
  },
  mounted() {},
  methods: {
    async opened() {
      // 预览时不需要单独请求
      if (this.is_preview) {
        this.reward_list = this.preview_list;
        const index = this.reward_list.findIndex(item => {
          return !this.cannotChose(item);
        });
        if (index != -1) {
          this.chooseIndex = index;
          this.reword_item = this.reward_list[index];
        }
      } else {
        await this.getList();
      }
      if (this.$refs.rewardList) {
        let dom = this.$refs.rewardList;
        if (dom.scrollHeight <= dom.offsetHeight) {
          this.show_reword_list_bg = false;
        }
      }
      if (this.$refs.infoTop) {
        let dom = this.$refs.infoTop;
        if (dom.scrollHeight <= dom.offsetHeight) {
          this.show_info_top_bg = false;
        }
      }
    },
    // 获取回报档列表
    async getList() {
      const {
        status,
        data
      } = await getRewardList({
        page: 0,
        page_size: 9999,
        pro_id: this.pro_id
      });
      if (status != 0) return;
      this.reward_list = data.list;
      const index = this.reward_list.findIndex(item => {
        return !this.cannotChose(item);
      });
      if (index != -1) {
        this.chooseIndex = index;
        this.reword_item = this.reward_list[index];
      }
    },
    // 刷新回报档内容详情
    async refreshRewardsItem(id) {
      const {
        status,
        data,
        message
      } = await refreshRewards({
        reward_id: id ? id : this.reword_item.id,
        pro_id: this.pro_id
      });
      if (status == 0) {
        this.reward_list[this.chooseIndex] = Object.assign(this.reward_list[this.chooseIndex], data);
        this.reword_item = this.reward_list[this.chooseIndex];
      } else {
        mdToast(message);
      }
    },
    // 切换回报档
    checkRewardItem(index, item) {
      // if (item.online_unpack_type > 0) {
      //     // eslint-disable-next-line no-undef
      //     location.href = userHeader.mdlink
      //     return
      // }

      if (index == this.chooseIndex) return;
      this.chooseIndex = index;
      if (item.id == -3) {
        this.reword_item = item;
        return;
      }
      // 预览时不需要刷新
      if (!this.is_preview) {
        this.refreshRewardsItem(item.id);
      } else {
        this.reword_item = this.reward_list[this.chooseIndex];
      }
    },
    // 去抽卡机
    goLuckycards() {
      if (this.is_preview) {
        return;
      }
      if (this.reword_item.draw_card_id) {
        MDRouter.goLuckycardsInfo(this.reword_item.draw_card_id);
      }
    },
    dealContent(str) {
      if (str) {
        return str.replace(/↵/g, '<br/>').replace(/\n/g, '<br/>');
      } else {
        return str;
      }
    },
    closePopup() {
      clearInterval(this.remainingTimer);
      clearInterval(this.onlineRemainingTimer);
      this.remainingTime = '00:00:00';
      this.onlineRemainingTime = '00:00:00';
      this.$emit('update:show', false);
    },
    // 不可选
    cannotChose(item) {
      let bol = false;
      if (item.num > 0 && item.back_count >= item.num // 已截止
      || (item.status == 300 || item.status == 301) && item.end_type == 1)
        // 已抢光
        {
          bol = true;
        }
      return bol;
    },
    // 立即购买支持-距结束倒计时
    getRemainingTime() {
      clearInterval(this.remainingTimer);
      this.remainingTimer = setInterval(() => {
        if (this.reword_item.remaining_time > 0) {
          this.reword_item.remaining_time--;
          let timeArr = fmTime(this.reword_item.remaining_time);
          this.remainingTime = (timeArr[3] ? timeArr[3] + '天' : '') + (timeArr[2] ? timeArr[2] + ':' : '') + (timeArr[1] ? timeArr[1] + ':' : '') + timeArr[0];
        } else {
          clearInterval(this.remainingTimer);
          this.refreshRewardsItem();
        }
      }, 1000);
    },
    // 点击刷新-还剩xx开始-倒计时
    getOnlineRemainingTime() {
      clearInterval(this.onlineRemainingTimer);
      this.onlineRemainingTimer = setInterval(() => {
        if (this.reword_item.online_remaining_time > 0) {
          this.reword_item.online_remaining_time--;
          let timeArr = fmTime(this.reword_item.online_remaining_time);
          this.onlineRemainingTime = (timeArr[3] ? timeArr[3] + '天' : '') + (timeArr[2] ? timeArr[2] + ':' : '') + (timeArr[1] ? timeArr[1] + ':' : '') + timeArr[0];
        } else {
          clearInterval(this.onlineRemainingTimer);
          this.refreshRewardsItem();
        }
      }, 1000);
    },
    // 去购买
    async goPay() {
      if (this.is_preview) {
        return false;
      }
      if (this.reword_item.online_unpack_type > 0) {
        this.$parent.$parent.getWxScheme();
        return;
      }
      if (this.reword_item.is_related_reward == 1) {
        const {
          status,
          data
        } = await checkReward({
          reward_id: this.reword_item.id,
          pro_id: this.pro_id
        });
        if (status == 0) {
          if (data) {// 已购买关联的必买档位
            // location.href = domain.wap + '/pay/back?id=' + this.reword_item.id + '&xid=' + this.pro_id + '&_mpos=goback_wap'
          } else {
            // 未购买关联的必买档位
            this.showRelatedReward = true;
            return;
          }
        } else {
          return;
        }
      }

      // 无偿支付，项目ID为空，手动赋值
      this.reword_item.pro_id = this.pro_id;
      // location.href = domain.wap + '/pay/back?id=' + this.reword_item.id + '&xid=' + this.pro_id + '&_mpos=goback_wap'
      sessionStorage.setItem('reword_item', JSON.stringify(this.reword_item));
      this.$router.push("/project/create_order");
    },
    // 回报档内容阴影
    infoTopScroll(e) {
      let dom = e.target;
      let h = dom.offsetHeight;
      let h_scroll = dom.scrollHeight;
      let t_scroll = dom.scrollTop;
      if (t_scroll + h + 10 > h_scroll) {
        this.show_info_top_bg = false;
      } else {
        this.show_info_top_bg = true;
      }
    },
    // 回报档阴影
    rewordScroll(e) {
      let dom = e.target;
      let h = dom.offsetHeight;
      let h_scroll = dom.scrollHeight;
      let t_scroll = dom.scrollTop;
      if (t_scroll + h + 10 > h_scroll) {
        this.show_reword_list_bg = false;
      } else {
        this.show_reword_list_bg = true;
      }
    }
  },
  watch: {
    reword_item: {
      handler(val) {
        if ((this.reword_item.status == 101 || this.reword_item.status == 102) && this.reword_item.online_remaining_time) {
          this.getOnlineRemainingTime();
        } else if (this.reword_item.status == 200 && this.reword_item.remaining_time) {
          this.getRemainingTime();
        } else {
          clearInterval(this.remainingTimer);
          clearInterval(this.onlineRemainingTimer);
        }
        if (val.is_related_reward == 1) {
          this.$nextTick(() => {
            if (val) {
              let num = 0;
              let width = document.querySelector('.related-reward').getBoundingClientRect().width;
              let parentW = document.querySelector('.cont-warp').getBoundingClientRect().width;
              num = width / (parentW / 750 * 24) - 2;
              if (computeTextLength(val.related_reward_desc) > num) {
                this.showIcon = true;
              } else {
                this.showIcon = false;
              }
            }
          });
        }
      },
      immediate: true
    }
  }
};